<template>
     <v-alert
        style="border-radius: 12px;"
        border="top"
        color="main_red"
        :type="`${alertType}`"
        dense
    >
        <span style="font-family: 'Catamaran', serif !important; letter-spacing: 0.0 !important; text-transform: none !important; font-size: 14px">{{ title }}</span>
    </v-alert>
</template>

<script>

export default {
    props: ['title', 'type'],
    data () {
        return {
        }
    },
    computed: {
        alertType: {
            get(){
                return this.type
            },
            set(){
                return false
            }
        }
    }
}
</script>
