module.exports = class PatientObject {
    constructor(object) {
        this.id = object?.userData.id,
        this.address = object?.userData.address;
        this.firstName = object?.userData.firstName;
        this.lastName = object?.userData.lastName;
        this.email = object?.userData.email;
        this.phoneNumber = object?.userData.phone;
        if (object === undefined) {
            this.gender = '';
        } else this.gender = object?.userData.gender === 'M' ? '1' : '2';
        this.birthdate = object?.userData.birthdateAsString;
        this.hospitalId = object?.medicalTeamData[0].hospitalId;
        this.mrn = object?.userData.patientMetadata?.medicalRegistrationNumber;
        this.height = object?.userData.patientMetadata?.heightInCm;
        this.weight = object?.userData.patientMetadata?.weightInKg;
        if (object === undefined) {
            this.smoker = ''
        } else this.smoker = object?.userData.patientMetadata?.isCigaretteSmoker ? '1' : '2';
        this.alcoolConsumtion = object?.userData.patientMetadata?.alcoolConsumtion;
        this.additionalInfo = object?.userData.patientMetadata?.metadataInfo;
        this.prescriptions = object?.userData.patientMetadata?.prescriptions;
        this.diagnose = object?.userData.patientMetadata?.diagnose
        this.userSettingsJson = object?.userData.userSettingsJson
    }
};