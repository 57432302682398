<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">{{ $t("patients") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-patient')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :headers-length="headers.length"
            :search="search"
            :items="filteredUsers"
            :items-per-page="filteredUsers.length"
            sort-by="id"
            item-key="id"
            fixed-header
            style="cursor: pointer"
            @click:row="userDetails"
            :height="testHeight"
          >
            <template v-slot:[`footer.prepend`]="{}">
              <v-btn
                v-if="roleName === 'HOSPITAL_ADMIN'"
                class="table-create-button"
                label="Test"
                @click="dialog = true"
              >
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("addNewPatientTooltip") }}</span>
              </v-btn>
            </template>
            <template v-slot:[`item.statusShortDescription`]="{ item }">
              <img :src="patient_icon(item.status)" height="24px" />
            </template>
          </v-data-table>
        </v-card>
        <AddPatientDialog
          v-if="dialog"
          :dialog="dialog"
          @false="closeDialog()"
        ></AddPatientDialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AddPatientDialog from "@/views/dialogs/PatientDialog.vue";
import { patient_secondary, patient_black } from "@/assets";

const libphonenumber = require("libphonenumber-js");

export default {
  components: {
    AddPatientDialog,
  },
  data() {
    return {
      search: "",
      loading: true,
      dialog: false,
      filteredUsers: [],
      headerClass: "black_inner--text !important;",
      libphonenumber: libphonenumber,
      patient_secondary: patient_secondary,
      patient_black: patient_black,
    };
  },
  computed: {
    ...mapState({
      usersFromRepo: (state) => state.users.users,
      patientsFromRepo: (state) => state.hospitals.patients,
      relatedPatients: (state) => state.hospitals.relatedPatients,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    ...mapGetters({
      roleName: "authentication/getRole",
      hospitalId: "authentication/getHospitalId",
      myMedicalTeam: "authentication/getMyMedicalTeam",
      pageHeight: "pageHeight",
    }),
    testHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerHeight - 320;
        case "sm":
          return window.innerHeight - 300;
        case "md":
          return window.innerHeight - 300;
        case "lg":
          return window.innerHeight - 280;
        case "xl":
          return window.innerHeight - 280;
        default:
          return 0;
      }
    },
    headers() {
      var headers = [];
      headers = [
        {
          text: this.$t("header-status"),
          value: "statusShortDescription",
          class: this.headerClass,
        },
        {
          text: this.$t("header-full-name"),
          value: "fullname",
          class: this.headerClass,
        },
        {
          text: this.$t("header-sex"),
          value: "sex",
          class: this.headerClass,
        },
        { text: this.$t("header-age"), value: "age", class: this.headerClass },
        {
          text: this.$t("header-primary-doctor"),
          value: "phone",
          class: this.headerClass,
        },
        {
          text: this.$t("header-medical-no"),
          value: "phone",
          class: this.headerClass,
        },
      ];
      return headers;
    },
  },
  methods: {
    calculateAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split("T")[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    userDetails(item) {
      if (this.roleName === "HOSPITAL_ADMIN") {
        return;
      }
      this.$router.push({
        name: "Patient Details",
        params: { id: `${item.id}` },
      });
    },
    async getPatients() {
      if (this.roleName !== "HOSPITAL_ADMIN") {
        await this.$store.dispatch("users/getUsers").then(() => {
          this.loading = false;
          this.filteredUsers = this.usersFromRepo.filter(
            (users) =>
              users.roleData.roleName === "PATIENT" &&
              this.myMedicalTeam.includes(users?.medicalTeamsData[0]?.id)
          );
          this.filteredUsers?.forEach((user) => {
            user["fullname"] = user.firstName + " " + user.lastName;
            user["age"] = this.calculateAge(user.birthdate);
            user["sex"] = user.gender === "M" ? "Male" : "Female";
            this.verifiersFromRepo.forEach((v) => {
              if (user.status === v.lowValue) {
                user.statusShortDescription = v.shortDescription;
                user.statusLongDescription = v.longDescription;
              }
            });
          });
          this.loading = false;
        });
      } else if (
        this.roleName === "HOSPITAL_ADMIN" &&
        this.hospitalId !== undefined
      ) {
        await this.$store
          .dispatch("hospitals/getRelatedPatients", this.hospitalId)
          .then(() => {
            this.filteredUsers = this.relatedPatients;
            this.filteredUsers?.forEach((user) => {
              user["fullname"] = user.firstName + " " + user.lastName;
              user["age"] = this.calculateAge(user.birthdate);
              user["sex"] = user.gender === "M" ? "Male" : "Female";
              this.verifiersFromRepo.forEach((v) => {
                if (user.status === v.lowValue) {
                  user.statusShortDescription = v.shortDescription;
                  user.statusLongDescription = v.longDescription;
                }
              });
            });
            this.loading = false;
          });
      } else if (
        this.roleName === "HOSPITAL_ADMIN" &&
        this.hospitalId === undefined
      ) {
        this.filteredUsers = [];
        this.loading = false;
      }
    },
    async getVerifiers() {
      let header = {
        domainValue: "USER_STATUS",
      };
      await this.$store.dispatch("verifiers/getVerifiersForDomain", header);
    },
    closeDialog() {
      this.dialog = false;
      this.getPatients();
    },
    patient_icon(status) {
      switch (status) {
        case 1:
          return this.patient_black;
        default:
          return this.patient_secondary;
      }
    },
  },
  async mounted() {
    await this.getVerifiers();
    await this.getPatients();
  },
};
</script>