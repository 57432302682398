<template>
  <v-dialog
    v-model="dialog"
    width="1200"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    class="dialog-style"
  >
    <v-card>
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title">Patient</span>
        <v-spacer />
        <v-icon small color="primary" @click="$emit('false')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-card-text color="primary" class="mt-5">
        <span
          >Welcome to patient registration form and setup process
          <span style="color: red">(required fields *)</span></span
        >
      </v-card-text>
      <v-form v-model="valid">
        <v-card flat style="overflow: hidden">
          <v-row class="pa-6" dense>
            <v-col cols="6">
              <v-row class="pl-4 pb-4">
                <span style="font-size: 18px">Personal details</span>
              </v-row>
              <v-row dense class="pb-2">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="userDetails.firstName"
                    label="Name *"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="userDetails.lastName"
                    label="Surname *"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense class="pb-2">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                    ref="email"
                    :name="$t('email')"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userDetails.email"
                      label="Email"
                      prepend-inner-icon="mdi-email mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :error-messages="(emailError = errors)"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                    ref="phoneNumber"
                    v-model="userDetails.phoneNumber"
                    validate-on-blur
                    label="Phone number *"
                    prepend-inner-icon="mdi-cellphone mr-4"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense cols="12">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-radio-group
                    class="pl-10 pt-0"
                    v-model="userDetails.gender"
                    row
                  >
                    <v-radio label="Male" value="1"></v-radio>
                    <v-radio label="Female" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <BirthdatePicker :inputValue.sync="birthdate" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-row class="pa-4">
                  <span style="font-size: 18px">Work details</span>
                </v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-select
                    v-model="userDetails.hospitalId"
                    label="Select Patient's Local Office"
                    prepend-inner-icon="mdi-hospital-building mr-1"
                    :items="hospitals"
                    item-value="id"
                    item-text="hospitalName"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-row class="pa-4 pt-8">
                  <span style="font-size: 18px">Address details</span>
                </v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-text-field
                    ref="address"
                    @blur="locationDetails()"
                    v-model="userDetails.address"
                    validate-on-blur
                    label="Address, State, postal_code, City, Country *"
                    prepend-inner-icon="mdi-map-marker mr-4"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    placeholder="Address, State, postal_code, City, Country"
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-4 pt-4 pb-4">
                <span style="font-size: 18px">Patient information</span>
              </v-row>
              <v-row dense cols="12" class="pb-2">
                <v-col cols="12" lg="12" md="6" sm="12">
                  <v-text-field
                    v-model="userDetails.mrn"
                    label="MRN"
                    prepend-inner-icon="mdi-account mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense cols="12" class="pb-2">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                    :name="$t('height')"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userDetails.height"
                      label="Height in cm *"
                      prepend-inner-icon="mdi-human-male-height mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <validation-provider
                    :name="$t('height')"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="userDetails.weight"
                      label="Weight in kg *"
                      prepend-inner-icon="mdi-weight-kilogram mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important; height: 40px"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense cols="12">
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-radio-group
                    class="pl-5 pt-0"
                    v-model="userDetails.smoker"
                    row
                  >
                    <v-radio label="Smoker" value="1"></v-radio>
                    <v-radio label="Non-smoker" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <v-select
                    v-model="userDetails.alcoolConsumtion"
                    :items="alcoolConsumtionLevels"
                    item-value="value"
                    item-text="text"
                    label="Alcool Consumtion Level?"
                    prepend-inner-icon="mdi-liquor mr-1"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                    :rules="[(rule) => !!rule || '']"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pl-4" cols="6">
              <v-row class="pl-4 pb-4">
                <span style="font-size: 18px">Additional data</span>
              </v-row>
              <v-row dense cols="12" class="px-1">
                <v-tabs
                  background-color="#E8E8E8"
                  color="primary"
                  grow
                  hide-slider
                  height="40px"
                  style="border-radius: 12px"
                  active-class="background-color: primary white--text"
                  v-model="tab"
                  left
                >
                  <v-tab class="tab-header-left">Additional info</v-tab>
                  <v-tab class="tab-header-middle" style=""
                    >Prescriptions</v-tab
                  >
                  <v-tab class="tab-header-right">Known diagnosis</v-tab>
                  <v-tab-item v-for="n in 3" :key="n" class="pt-4 px-0">
                    <v-textarea
                      v-if="n === 1"
                      v-model="userDetails.additionalInfo"
                      label="Additional info"
                      prepend-inner-icon="mdi-information-variant mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important"
                      filled
                      rows="17"
                      row-height="30"
                      shaped
                    ></v-textarea>
                    <v-textarea
                      v-if="n === 2"
                      v-model="userDetails.prescriptions"
                      label="Prescriptions"
                      prepend-inner-icon="mdi-prescription mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important"
                      filled
                      rows="17"
                      row-height="30"
                      shaped
                    ></v-textarea>
                    <v-textarea
                      v-if="n === 3"
                      v-model="userDetails.diagnose"
                      label="Any known diagnose"
                      prepend-inner-icon="mdi-text-box-outline mr-1"
                      dense
                      background-color="#E8E8E8"
                      rounded
                      outlined
                      style="border-radius: 12px !important"
                      filled
                      rows="17"
                      row-height="30"
                      shaped
                    ></v-textarea>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <Alert
              class="ml-5"
              v-if="showErrors"
              :title="title"
              :type="alertType"
            ></Alert>
            <v-spacer></v-spacer>
            <div class="my-6">
              <v-btn class="table-create-button" @click="$emit('false')">
                <v-icon class="icon-cancel">mdi-window-close</v-icon>
                <span>{{ $t("cancel") }}</span>
              </v-btn>
              <v-btn class="table-create-button mx-4" @click="createPatient">
                <v-icon class="icon">mdi-plus</v-icon>
                <span>{{ $t("save") }}</span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import Alert from "@/views/Client/components/AlertDialog.vue";
import BirthdatePicker from "@/views/Client/components/BirthdatePicker";
import PatientObject from "../../data/models/PatientObject";
import { phoneNumberFormatter } from "../../utils/luxon-formater";

export default {
  components: {
    Alert,
    BirthdatePicker,
  },
  props: ["dialog", "type", "patient"],
  data() {
    return {
      title: "Something went wrong",
      alertType: false,
      showErrors: false,
      validationErrors: [],
      tabs: [
        {
          text: "Additional info",
          cardColor: "#6600FF",
        },
        {
          text: "Prescriptions",
          cardColor: "#D30202",
        },
        {
          text: "Any known diagnose",
          cardColor: "#FF931E",
        },
      ],
      userLoading: false,
      snackStatus: false,
      snackColor: "",
      firstName: "",
      lastName: "",
      email: "",
      emailError: false,
      username: "",
      phoneNumber: null,
      address_info: {
        address: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        lat: 42.001071,
        lng: 20.957554,
      },
      gender: "",
      smoker: "",
      alcoolConsumtionLevels: [
        { text: "Frequent consumption", value: 2 },
        { text: "Consuming rarely", value: 1 },
        { text: "Not consuming", value: 0 },
      ],
      birthdate: "",
      valid: false,
      metadataInfo: "",
      heightInCm: null,
      weightInKg: null,
      medicalRegistrationNumber: null,
      alcoolConsumtion: null,
      prescriptions: "",
      diagnose: "",
      phoneCodeModel: "",
      hospitals: [],
      hospitalId: -1,
      countries: [],
      addressToValidate: null,
      formattedAddress: null,
      timezone: null,
      tab: 0,
    };
  },
  computed: {
    ...mapState({
      hospitalsFromRepo: (state) => state.hospitals.hospitals,
      timeZones: (state) => state.verifiers.timeZones,
    }),
    userDetails() {
      return new PatientObject(this.patient);
    },
  },
  async mounted() {
    await this.getHospitals();
    if (this.type === "edit") {
      this.addressData();
      this.birthdate = this.userDetails?.birthdate;
    }
  },
  methods: {
    async addressData() {
      this.address_info = {
        address: this.userDetails.address,
        postal_code: this.patient.userData.zip,
        city: this.patient.userData.city,
        state: this.patient.userData.state,
        country: this.patient.userData.country,
        lat: this.patient.userData.addressLatitude,
        lng: this.patient.userData.addressLongitude,
      };
    },
    async locationDetails() {
      if (this.userDetails.address) {
        const geo_location = await this.$store.dispatch(
          "googleAPI/getGeoLocation",
          this.userDetails.address
        );
        const address_components = geo_location.address_components;
        this.address_info.lat = geo_location.geometry.location.lat;
        this.address_info.lng = geo_location.geometry.location.lng;
        this.formattedAddress = geo_location.formatted_address;
        this.address_info.address = this.formattedAddress;
        address_components.forEach((ac) => {
          if (ac.types.includes("administrative_area_level_1")) {
            this.address_info.state = ac.long_name;
          }
          if (ac.types.includes("locality")) {
            this.address_info.city = ac.long_name;
          }
          if (ac.types.includes("country")) {
            this.address_info.country = ac.long_name;
          }
          if (ac.types.includes("postal_code")) {
            this.address_info.postal_code = ac.long_name;
          }
        });
        if (this.address_info.lat !== null) {
          const tz = await this.timeZone(
            this.address_info.lat,
            this.address_info.lng
          );
          if (tz) {
            this.timezone = tz.zoneName;
          }
        }
      }
    },
    async editPatientMetaData(userId) {
      const metaDataBody = {
        userId: userId,
        metadataInfo: this.userDetails.additionalInfo,
        heightInCm: parseInt(this.userDetails.height),
        weightInKg: parseInt(this.userDetails.weight),
        ssn: this.ssn,
        medicalRegistrationNumber: this.userDetails.mrn,
        patientIdentifier: this.patientIdentifier,
        isCigaretteSmoker: this.userDetails.smoker === "1" ? true : false,
        alcoolConsumtion: this.userDetails.alcoolConsumtion,
        prescriptions: this.userDetails.prescriptions,
        diagnose: this.userDetails.diagnose,
      };
      console.log("ckto", metaDataBody);
      await this.$store.dispatch(
        "patientmetadata/editPatientMetaData",
        metaDataBody
      );
    },
    async assignPatientToHospital(userId) {
      const body = {
        hospitalId: this.hospitalId,
        userId: userId,
      };
      await this.$store.dispatch("hospitals/assignPatientToHospital", body);
    },
    async timeZone(lat, lng) {
      let object = {
        lat: lat,
        lng: lng,
      };
      const time_zone = await this.$store.dispatch(
        "googleAPI/getTimeZone",
        object
      );
      return time_zone;
    },
    checkValid() {
      this.validationErrors = [];
      if (!phoneNumberFormatter(this.userDetails.phoneNumber)) {
        this.validationErrors.push("phone");
      }
      if (this.emailError.length > 0) {
        this.validationErrors.push("email");
      }
      if (this.address_info.address === "") {
        this.validationErrors.push("address");
      }
      if (this.validationErrors.length > 0) {
        return false;
      } else return true;
    },
    async createPatient() {
      const valid = this.checkValid();
      if (!valid) {
        this.title =
          "Provided data is not valid: " + this.validationErrors.toString();
        this.alertType = "error";
        this.showErrors = true;
      }
      if (valid) {
        this.userLoading = true;
        this.snackStatus = false;
        const body = {
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          phone: this.userDetails.phoneNumber.split(" ").join(""),
          email: this.userDetails.email,
          address: this.address_info.address,
          zip: this.address_info.postal_code,
          city: this.address_info.city,
          state: this.address_info.state,
          country: this.address_info.country,
          addressLatitude: this.address_info.lat,
          addressLongitude: this.address_info.lng,
          username: this.userDetails.email,
          password: "123456789",
          gender: this.userDetails.gender === "1" ? "M" : "F",
          birthdate: this.birthdate,
          roleId: 5,
          userTimezone: this.userDetails.timezone,
        };
        if (this.type === "edit") {
          body.id = this.userDetails.id;
          (body.status = 1),
            (body.userSettingsJson = this.userDetails.userSettingsJson);
          await this.$store
            .dispatch("users/updateUser", body)
            .then(async (res) => {
              if (res) {
                await this.editPatientMetaData(res.id).then(() => {
                  this.userLoading = false;
                  this.$emit("false");
                  this.$emit("edited");
                });
              } else {
                this.userLoading = false;
                this.snackStatus = true;
                this.snackColor = "deep-orange darken-4";
                this.title = res.msg;
              }
            });
        } else {
          await this.$store
            .dispatch("users/createUser", body)
            .then(async (res) => {
              if (res.statusCode >= 200 && res.statusCode < 300) {
                await this.assignPatientToHospital(res.data.id);
                await this.editPatientMetaData(res.data.id).then(() => {
                  this.userLoading = false;
                  this.$emit("false");
                });
              } else {
                this.userLoading = false;
                this.snackStatus = true;
                this.snackColor = "deep-orange darken-4";
                this.title = res.msg;
              }
            });
        }
      }
    },
    async getHospitals() {
      await this.$store.dispatch("hospitals/getHospitals").then(() => {
        if (this.hospitalsFromRepo !== null) {
          this.hospitals = this.hospitalsFromRepo;
        } else this.hospitals = [];
      });
    },
  },
};
</script>